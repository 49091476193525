<template lang="html">
  <div class="Dashboard">
    <dashboard-top>
      <screen-title title="Subscription"></screen-title>
    </dashboard-top>

    <div class="container-full">
      <section-title title="Current subscription" />
      <div class="currentplan">
        <Plan v-if="current" :plan="current" current :key="current.id"></Plan>
        <div class="trial-info__wrapper" v-if="!current && plans">
          <div class="trial-info" v-if="onGenericTrial">
            You are currently within your free trial period. Your trial will
            expire on <strong>{{ trial_ends_on | moment('LL') }}.</strong>
          </div>
          <div class="trial-info trial-info--expired" v-if="!onGenericTrial">
            Your free trial period is over.
            <strong>Please select a plan to continue using Selectr.</strong>
          </div>
        </div>
      </div>

      <section-title title="Change subscription" />
      <fade-transition mode="out-in">
        <div class="plans" :key="plans.length" v-if="plans">
          <Plan
            v-for="plan in plans"
            :key="plan.id + Math.random()"
            :plan="plan"
            :current-plan="current"
          ></Plan>
        </div>
        <CardLoader v-else />
      </fade-transition>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import CardLoader from '@/components/Loaders/CardLoader'
  import Plan from '@/components/Plans/Plan'

  export default {
    name: 'Subscription',
    components: { Plan, CardLoader },
    created() {
      this.$store.dispatch('subscriptions/GET_PLANS')
    },
    computed: {
      ...mapState({
        plans: (state) => state.subscriptions.available,
        current: (state) => state.subscriptions.current,
        trial_ends_on: (state) => state.user.team.trial_ends_at,
        onGenericTrial: (state) => state.user.team.onGenericTrial,
      }),
    },
  }
</script>
