<template>
  <content-loader
    :width="800"
    :height="200"
    :speed="2"
    viewBox="0 0 800 200"
    :primaryColor="primaryColor"
    secondaryColor="#D1D1D1"
  >
    <rect x="0" y="0" rx="5" ry="5" width="800" height="200" />
  </content-loader>
</template>

<script>
  import { ContentLoader } from "vue-content-loader"
  export default {
    components: { ContentLoader },
    props: {
      primaryColor: {
        type: String,
        default: '#fff',
      }
    }
  }
</script>
