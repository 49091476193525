<template lang="html">
  <article class="Card Plan" v-show="showPlan">
    <div class="Plan__content">
      <div class="Plan__notice mb-32" v-if="notice">
        <p class="title-6">Notice</p>
        <p>
          You have cancelled your subscription to the
          <strong>{{ plan.name }}</strong
          >.
        </p>
        <p>
          The benefits of your subscription will continue until your current
          billing period ends on {{ plan.ends_at | moment('MMMM, Do YYYY') }}.
          You may resume your subscription at no extra cost until the end of the
          billing period.
        </p>
      </div>
      <header class="Plan__header">
        <h1 class="title-4">{{ plan.name }}</h1>
        <div class="Plan__price title-6">
          <span>{{ plan.price | currency }} </span>
          <span class="text-sm text-bold text-grey">({{ plan.interval }})</span>
        </div>
      </header>
      <div class="Plan__body" v-if="!notice">
        <div class="Plan__features">
          <h2 class="title-6 text-grey">Features</h2>
          <ul class="Plan__list">
            <li v-for="(feature, i) in plan.features" :key="i">
              {{ feature }}
            </li>
          </ul>
        </div>
        <div class="Plan__attributes" v-if="plan.attributes.length > 0">
          <h2 class="title-6 text-grey">Attributes</h2>
          <ul class="Plan__list">
            <li v-for="(attr, i) in plan.attributes" :key="i">{{ attr }}</li>
          </ul>
        </div>
      </div>
      <div class="Plan__footer">
        <button
          class="btn btn--error"
          v-if="current && !notice"
          @click="cancel"
        >
          Cancel Subscription
        </button>
        <button
          class="btn btn--outlined"
          v-if="current && notice"
          @click="resubscribe"
        >
          Resume subscription
        </button>
        <button
          class="btn btn--primary"
          @click="subscribe"
          v-if="!this.$store.getters.hasToFillPayment && !current"
        >
          {{ primaryText }} {{ plan.name }}
        </button>
        <button
          class="btn btn--dark"
          @click="addPayment"
          v-if="this.$store.getters.hasToFillPayment && !current"
        >
          {{ primaryText }}
        </button>
      </div>
    </div>
  </article>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'Plan',
    props: {
      plan: {
        type: Object,
        required: true,
      },
      current: {
        type: Boolean,
        default: false,
      },
      currentPlan: {
        required: false,
      },
    },
    methods: {
      subscribe() {
        let confirmation = confirm(
          'Your card will be charged. Are you sure you want to subscribe?',
        )
        if (confirmation) {
          if (this.hasCurrent && this.notice) {
            this.$store.dispatch('subscriptions/UPDATE_SUBSCRIPTION', {
              plan: this.plan,
              team: this.team,
            })
          } else {
            this.$store.dispatch('subscriptions/SUBSCRIBE', {
              plan: this.plan,
              team: this.team,
            })
          }
        }
      },
      resubscribe() {
        this.$store.dispatch('subscriptions/UPDATE_SUBSCRIPTION', {
          plan: this.plan,
          team: this.team,
        })
      },

      addPayment() {
        this.$router.push({
          name: 'profile-settings',
          hash: '#payment-methods-&-invoice-details',
        })
      },
      cancel() {
        this.$store.dispatch('subscriptions/CANCEL_SUBSCRIPTION', {
          plan: this.plan,
          team: this.team,
        })
      },
    },
    computed: {
      ...mapState({
        team: (state) => (state.user.team ? state.user.team.id : null),
      }),
      hasCurrent() {
        return this.currentPlan ? true : false
      },
      showPlan() {
        return !(this.hasCurrent && this.currentPlan.id == this.plan.id)
      },
      primaryText() {
        return this.$store.getters.hasToFillPayment
          ? 'Add payment method before subscribing'
          : this.hasCurrent
          ? 'Switch to'
          : 'Subscribe to'
      },
      notice() {
        return this.plan.ends_at || this.plan.trial_ends_at
      },
    },
  }
</script>
